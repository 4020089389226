const projects = [
  {
    title: 'TWHelp',
    description: 'Various tools for the browser-based game Tribal Wars.',
    technologies: [
      'Go',
      'go-chi',
      'DiscordGo',
      'React',
      'Next.js',
      'PostgreSQL',
      'RabbitMQ',
      'Docker',
      'Kubernetes',
      'Terraform',
      'Ansible',
      'OpenTelemetry',
      'Traefik',
    ],
    git: 'https://gitea.dwysokinski.me/twhelp',
    live: 'https://tribalwarshelp.com/api/v1/swagger/index.html',
    fluid: 'projects/twhelp.png',
  },
  {
    title: 'Zdam Egzamin Zawodowy',
    description:
      'Mobile & web app for practising the theoretical part of the vocational exam.',
    technologies: [
      'Go',
      'GraphQL',
      'gqlgen',
      'go-chi',
      'PostgreSQL',
      'TypeScript',
      'React',
      'React Native',
      'Next.js',
      'Create React App',
      'Traefik',
      'Docker',
      'Ansible',
    ],
    fluid: 'projects/zdam.png',
    live: 'https://zdamegzaminzawodowy.pl/',
    git: 'https://github.com/zdam-egzamin-zawodowy',
  },
  {
    title: 'dwysokinski.me',
    description: '',
    technologies: ['React', 'Gatsby', 'Material-UI'],
    git: 'https://gitea.dwysokinski.me/Kichiyaki/dwysokinski.me',
    live: 'https://dwysokinski.me',
    fluid: 'projects/dw.png',
  },
  {
    title: 'Freshline',
    description: '',
    technologies: ['Wordpress', 'CSS', 'Bootstrap'],
    live: 'http://fresh-line.pl/',
    fluid: 'projects/freshline.png',
  },
];

export default projects;
