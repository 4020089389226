const isSmoothScrollSupported = () =>
  'scrollBehavior' in document.documentElement.style;

const smoothScroll = id => e => {
  if (!isSmoothScrollSupported()) {
    return;
  }

  e.preventDefault();
  document.querySelector('#' + id).scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
};

export default smoothScroll;
